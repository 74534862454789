<template>
  <div class="wr">
    <div class="walletTop">
      <div class="card">
        <div class="name">
          <div class="pic"><img src="../../assets/images/wallet.png" alt=""></div>
          <span>{{loginInfo.HoldName}}</span>
        </div>
        <div class="desc">
          <h5>{{$t('wallet.accountBalance')}}</h5>
          <p @click="mixinOpenWallet" style="cursor:pointer"><strong>{{remain}}</strong>{{$t('wallet.vpoint')}}</p>
        </div>
      </div>
      <ul class="list">
        <li @click="mixinOpenWallet">
          <div class="img">
            <i-icon name="iconchongzhi-253"></i-icon>
          </div>
          <div class="text">
            <h4>{{$t('wallet.addVpoint')}}</h4>
            <p>{{$t('wallet.addVpointDesc')}}</p>
          </div>
        </li>
        <li @click="$router.push('/wallet')">
          <div class="img">
            <i-icon name="icondingdan-256"></i-icon>
          </div>
          <div class="text">
            <h4>{{$t('wallet.recharge')}}</h4>
            <p>{{$t('wallet.readDetail')}}</p>
          </div>
        </li>
        <li @click="$router.push('/vip/invoice')" v-if="$i18n.locale === 'zh'">
          <div class="img">
            <i-icon name="iconfapiao-255"></i-icon>
          </div>
          <div class="text">
            <h4>{{$t('wallet.invoiceManage')}}</h4>
            <p>{{$t('wallet.invoiceManageDesc')}}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="walletBottom">
      <div class="search-area">
        <h2>{{$t('wallet.recgargeTitle')}}</h2>
        <div class="searchBar">
          <div class="search-bars">
            <el-button type="primary" class="obtn" plain @click="() => dialogInfoVisible=true" size="small" v-ripple>
              {{$t('wallet.openWallet')}}</el-button>

            <el-button type="primary" plain :loading="exportButtonLoading" @click="exportHandle" circle v-ripple class="ebtn">
              <i-icon name="iconxinxiguanli_daochu_weixuanzhong" v-if="!exportButtonLoading"></i-icon>
            </el-button>
            <!-- <el-button type="primary" class="border" plain @click="exportHandle" icon="el-icon-download"
              :loading="exportButtonLoading" v-ripple>{{$t('wallet.export')}}</el-button> -->
            <search-group :options="options" style="float: right;" @click="click"
              @input="({select,value,Op}) => {searchName=select;searchText=value,searchOption=Op}"
              :field="{select: searchName, value: searchText}"></search-group>
            <el-checkbox v-model="hasChild" style="float:right;line-height:36px;">{{$t('wallet.includeChild')}}
            </el-checkbox>
          </div>
        </div>
      </div>
      <el-table :data="tableData" :height="tableHeight" @sort-change="sortChange" border @header-dragend="saveHeaderDragWidth"
        class="v-table" size="mini" ref="multipleTable" v-loading="tableLoading"
        :element-loading-text="tableLoadingText">
        <el-table-column sortable="custom" prop="OccurTime" :label="$t('wallet.time')"
          :width="TW($t('wallet.time'), 170)" show-overflow-tooltip>
          <p slot-scope="{row}">{{miment(row.OccurTime).format()}}</p>
        </el-table-column>
        <el-table-column sortable="custom" prop="OccurName" :label="$t('wallet.name')"
          :width="TW($t('wallet.name'), 150)">
          <template slot-scope="{row}">
            <p class="primaryText">{{row.OccurName}}</p>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="OccurMoney" :label="$t('wallet.vpoint')"
          :width="TW($t('wallet.vpoint'), 80)">
          <template slot-scope="{row}">
            <p v-if="row.OccurMoney>=0" style="color:#4CAF50;">+{{row.OccurMoney}}</p>
            <p v-else style="color:#f44336;">-{{-row.OccurMoney}}</p>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="RemainTotalBalance" :label="$t('wallet.remain')"
          :width="TW($t('wallet.remain'), 100)">
          <template slot-scope="{row}">
            <p>{{row.RemainMoney}}</p>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="OrderNO" :label="$t('wallet.remarkOrder')"
          :width="TW($t('wallet.remarkOrder'), 250)">
          <template slot-scope="{row}">
            <p>{{row.OrderNO}}</p>
            <p style="color:#9E9E9E;" v-if="row.Remark && row.Remark.indexOf('订单号:')===0">订单号:<span class="i-link"
                @click="openDialogOrderDetail(row.Remark.substr(4))">{{row.Remark.substr(4)}}</span></p>
            <p style="color:#9E9E9E;" v-else>{{row.Remark}}</p>
          </template>
        </el-table-column>
        <el-table-column sortable="custom" prop="Name" :label="$t('wallet.user')" min-width="120px">
          <template slot-scope="{row}">
            <p class="primaryText" :title="row.FullPath">{{row.HoldName}} <span
                class="successText">{{row.CreateUserName}}</span></p>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="v-pager" :page-size="pageSize" :page-sizes="pageSizes" :pager-count="11"
        :current-page="pageIndex" layout="total, sizes, prev, pager, next, jumper" :total="pageTotalCount"
        @current-change="pageCurrentChange" @size-change="pageSizeChange" popper-class="custom">
      </el-pagination>

      <vcl-code v-if="false" class="ima-4" :width="400" :height="150"></vcl-code>

    </div>

    <el-dialog :title="$t('wallet.wallet')" append-to-body width="400px" custom-class="v-dialog v-dialog--wallet"
      :visible.sync="mixinDialogWalletVisible" v-if="mixinDialogWalletVisibleReal" @closed="mixinDialogWalletClosed">
      <wallet-info @close="() => mixinDialogWalletVisible = false" @send="mixinSend"></wallet-info>
    </el-dialog>
    <el-dialog :title="$t('wallet.sendVPoint')" append-to-body custom-class="v-dialog v-dialog--sm v-dialog--mdpadding"
      :visible.sync="mixinDialogWalletSendVisible" v-if="mixinDialogWalletSendVisibleReal"
      @closed="mixinDialogWalletClosed">
      <wallet-send @close="() => mixinDialogWalletSendVisible = false"></wallet-send>
    </el-dialog>


    <el-dialog :title="$t('wallet.wallet')" append-to-body width="400px" :visible.sync="dialogInfoVisible"
      custom-class="v-dialog v-dialog--wallet">
      <wallet-info v-if="dialogInfoVisible" @close="() => dialogInfoVisible = false" @send="send"></wallet-info>
    </el-dialog>

    <el-dialog :visible.sync="orderDetailListVisible" :title="$t('wallet.orderDetail')"
      custom-class="v-dialog v-dialog--sm v-dialog--mdpadding v-dialog--footer">
      <el-table :data="orderDetailList" class="v-table__customStyle" row-key="ObjectID" style="width:100%;" size="mini"
        max-height="410" stripe ref="multiTable">
        <el-table-column :label="$t('wallet.vehicleName')" prop="VehicleName" width="150"></el-table-column>
        <el-table-column :label="$t('wallet.holdName')" prop="Name" width="150"></el-table-column>
        <el-table-column :label="$t('wallet.deviceID')" prop="SIM2" width="150">
          <span slot-scope="{row}">{{row.SIM2||row.SIM}}</span>
        </el-table-column>
        <el-table-column :label="$t('wallet.amount')" prop="ServiceEndDate" width="80">
          <template slot-scope="{row}">
            <p>{{row.Months/12}} {{$t('wallet.year')}} </p>
          </template>
        </el-table-column>
        <el-table-column :label="$t('wallet.vipEndDate')">
          <template slot-scope="{row}">
            <p>{{miment(row.EndTime).format('YYYY-MM-DD')}}</p>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button @click="orderDetailListVisible=false">{{$t('common.close')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    VclCode
  } from 'vue-content-loading'
  import {
    mapGetters
  } from 'vuex'
  import miment from 'miment'
  import * as API from '@/api/wallet'
  import mixinWallet from '@/views/mixins/mixin-wallet'

  import IDropDown from '@/base/i-dropdown/i-dropdown'
  import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
  import mixin from '@/views/manage/mixin'
  import {
    Filter,
    Op
  } from '@/api/config'
  import TreeUser from '@/views/common/tree-user'
  import WalletInfo from './wallet-info'
  import WalletSend from './wallet-send'
  import {
    saveBlob
  } from '@/common/utils'
  import {
    QueryMultiOrderDetail
  } from '@/api/fee'

  export default {
    computed: {
      ...mapGetters([
        'loginInfo'
      ])
    },
    components: {
      IDropDown,
      IDropDownOption,
      VclCode,
      TreeUser,
      WalletInfo,
      WalletSend
    },
    data() {
      return {
        miment,
        tableLoading: false,
        remain: 0,
        options: [{
            label: '订单编号',
            value: 'P.OrderNO',
            Op: Op.Contains
          },
          {
            label: '服务名称',
            value: 'P.OccurName',
            Op: Op.Contains
          },
          {
            label: '用户',
            value: 'H.Name',
            Op: Op.Contains
          },
          {
            label: '账号',
            value: 'U.UserName',
            Op: Op.Contains
          },
          {
            label: '备注',
            value: 'P.Remark',
            Op: Op.Contains
          }
        ],
        riskListOptions: [],
        isAnimating: false, // 是否正在动画
        dialogInfoVisible: false,
        dialogSendVisible: false,
        orderDetailListVisible: false,
        orderDetailList: [],
        tableHeight:'calc(100% - 112px)'
      }
    },
    watch: {
      '$i18n.locale': 'getOptions'
    },
    methods: {
      click() {
        this.pageIndex = 1
        this.queryHandle()
      },
      getOptions() {
        this.options = [{
            label: this.$t('wallet.orderNo'),
            value: 'P.OrderNO',
            Op: Op.Contains
          },
          {
            label: this.$t('wallet.name'),
            value: 'P.OccurName',
            Op: Op.Contains
          },
          {
            label: this.$t('wallet.createUser'),
            value: 'H.Name',
            Op: Op.Contains
          },
          {
            label: this.$t('wallet.account'),
            value: 'U.UserName',
            Op: Op.Contains
          },
          {
            label: this.$t('wallet.remark'),
            value: 'P.Remark',
            Op: Op.Contains
          }
        ]
      },
      rowClick(row) {
        this.$router.push(`/risk/people/detail?id=${row.RID}`)
      },
      selectableHandle() {
        return true
        //return row.UserID != this.loginInfo.UserID
      },
      queryHandle() {
        const promise = new Promise((resolve, reject) => {
          const filters = []

          this.searchText && this.searchText.length > 0 &&
            (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))

          this.tableLoading = true
          API.Query(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, this.currentHoldId, this
            .hasChild).then(ret => {
            this.tableData = ret.data.list.slice()
            this.pageTotalCount = ret.data.total
            this.tableLoading = false
            this.remain = ret.data.vpoint
            resolve()
          }).catch(err => {
            this.print('error', err.message)
            this.tableLoading = false
            reject(err)
          })
        })
        return promise
      },
      getRiskTypes(ids) {
        const idArr = ids.split(',').map(k => parseInt(k))
        const types = this.riskListOptions.filter(k => {
          return idArr.indexOf(k.RID) != -1
        }).map(k => k.RiskType)
        return types
      },
      exportHandle() {
        this.exportButtonLoading = true
        API.Export(this.currentHoldId, this.hasChild).then(ret => {
          this.exportButtonLoading = false
          saveBlob(ret.data, `${this.$t('wallet.wallet')}.xlsx`)
        })
      },
      refreshHandle() {
        this.pageIndex = 1
        this.queryHandle()
      },
      send() {
        this.dialogInfoVisible = false
        this.dialogSendVisible = true
      },
      sum(list, compare) {
        const temp = list.map(compare)
        let total = 0
        temp.forEach(k => total += k || 0)
        return total
      },
      fetchMultiOrderDetail(orderId) {
        const promise = new Promise((resolve) => {
          QueryMultiOrderDetail(orderId).then(ret => {
            resolve(ret.data)
          })
        })
        return promise
      },
      async openDialogOrderDetail(orderId) {
        const list = await this.fetchMultiOrderDetail(orderId)
        this.orderDetailList = list
        this.orderDetailListVisible = true
      },
    },
    created() {
      this.getOptions()
      this.pageIndex = 1
      this.queryHandle()
    },
    mixins: [mixinWallet, mixin]
  }

</script>

<style lang="scss" scoped>
  .el-row {
    padding: 24px;
    position: relative;

    &.wallet-top {
      background-color: #FAFAFA;
      align-items: stretch;
    }

    .wallet-info {
      display: flex;
      flex: 0 0 420px;

      .wallet-img {
        margin-right: 16px;

      }

      .wallet-title-wrapper {
        .wallet-title {
          font-size: 18px;
          line-height: 28px;
          color: rgba(0, 0, 0, .26);
        }

        .wallet-sub-title {
          font-size: 24px;
          color: green;
          margin-top: 8px;
          cursor: default;
        }
      }
    }

    .wallet-remain {
      margin-left: 16px;

      .wallet-title {
        font-size: 18px;
        line-height: 28px;
        color: rgba(0, 0, 0, .26);
      }

      .wallet-sub-title {
        font-size: 24px;
        color: green;
        margin-top: 8px;
        cursor: default;
      }
    }

    .wallet-split {
      flex: 0 0 1px;
      background-color: rgba(0, 0, 0, .10);
    }

    .wallet-title {
      font-size: 18px;
      line-height: 28px;
      color: rgba(0, 0, 0, .26);
    }

    .wallet-history {
      display: flex;

      .wallet-history-list {
        flex: 1 1;
        padding: 16px 0;

        .list-item {
          display: flex;
          font-size: 16px;
          line-height: 28px;
          margin: 8px 0;

          .col1 {
            flex: 0 0 100px;

            &.green {
              color: green;
            }

            &.red {
              color: red;
            }
          }

          .col2 {
            flex-grow: 1;
            color: rgba(0, 0, 0, .54);
          }

          .col3 {
            flex: 0 0 220px;
            color: rgba(0, 0, 0, .54);
          }

          .list-action {
            color: rgb(0, 122, 204);
            cursor: pointer;

            &:hover {
              color: rgba(0, 122, 204, .54);
            }
          }
        }
      }

      .wallet-action {
        flex: 0 0 450px;
        margin: -16px 0;

        .wallet-action-box {
          margin: 16px 0;
          border-left: 2px solid #CC3333;
          padding: 16px 24px;
          background-color: #FAFAFA;
          height: 80px;
          width: 300px;
          cursor: pointer;
          color: rgba(0, 0, 0, .54);
          transition: all .3s ease-out;

          .box-title {}

          .box-desc {
            color: rgba(0, 0, 0, .26);
            margin-top: 8px;
          }

          &:hover {
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
            color: rgba(0, 128, 0, .56);
          }
        }
      }
    }
  }

  .walletTop {
    margin: 20px 30px 0 30px;
    display: flex;

    .card {
      width: 648px;
      height: 86px;
      border-radius: 10px;
      background: #1890ff url('../../assets/images/wbg.png') no-repeat center;
      background-size: 100% auto;
      color: #fff;
      display: flex;

      .name {
        display: flex;
        margin-top: 20px;
        height: 48px;
        border-right: 1px solid #fff;
        .pic {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: #fff;
          margin-left: 30px;

          img {
            width: 100%;
          }
        }

        span {
          width:160px;
          line-height: 26px;
          margin-left: 10px;
        }
      }

      .desc {
        padding-left: 30px;
        padding-top: 14px;

        h5 {
          line-height: 26px;
          font-size: 16px;
          font-weight: normal;
        }

        p {
         font-size: 14px;
          strong {
            font-size: 32px;
            font-weight: 600;
            margin-right: 10px;
          }
        }
      }
    }

    .list {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;

      li {
        list-style: none;
        margin-left: 20px;
        flex-grow: 1;
        border-radius: 12px;
	      border: solid 1px rgba(144, 152, 171, 0.2);
        text-align: left;
        cursor: pointer;
        transition: all 0.5s;
        display: flex;

        .img {
          width: 30px;
          height: 30px;
          font-size: 26px;
          margin-top: 26px;
          margin-right: 8px;
          margin-left:15px;
        }
        .text{
          padding-top: 15px;
          h4 {
            font-size: 18px;
            font-weight: normal;
            line-height: 32px;
            color: #333333;
          }

          p {
            font-size: 14px;
            color: #666666;
          }
        }

       
      }

      li:hover {
        border: solid 1px #1890ff;
        transform: translate(-2px, -2px);
        box-shadow: 0 0 15px #ccc;
      }
    }
  }

  .walletBottom {
    margin: 20px 30px 0 30px;
    border: 1px solid #ececec;
    height: calc(100% - 145px);
    overflow: hidden;
    color: #4d6182;
    border-radius: 10px;
    .search-area {
      padding:10px 20px 0 20px;
      margin-bottom: 10px;
      height: auto;
      overflow: hidden;
      display: flex;
      justify-content: space-between;


      h2 {
        font-weight: 600;
        font-size: 14px;
        text-indent: 10px;
        line-height: 44px;
        color: #333333;
      }
      .obtn{
        height: 32px;
        border-radius: 20px;
        background-color: #1890ff;
        color:#fff;
        border:none;
        float: left;
      }
      .ebtn{
        font-size: 20px;
        padding: 5px;
        border: 1px solid rgba(24, 144, 255, 0.5);
        background-color: #FFF;
        color: #1890ff;
        float: left;
        margin-right: 15px;
         & ::v-deep .icon{
           display: block;
         }
        & ::v-deep {
          .el-icon-loading{
            display: block;
            font-size: 20px;
            padding: 5px;
          }
        }
      }
    }
    .v-table{
      width:99%;
      margin: 0 auto;
      border-left:none;
      & ::v-deep th{
        background-color: #fff;
        border-right: none;
      }
      & ::v-deep td{
        border-right: none;
      }
    }
    .v-table::before{
      display: none;;
    }
    .v-table::after{
      display: none;;
    }
    .v-pager{
      padding: 10px 0;
      text-align: center;
    }
  }
  .wr{
    height: 100%;
  }

</style>
