import WalletInfo from '@/views/wallet/wallet-info'
import WalletSend from '@/views/wallet/wallet-send'

let mixin = {
  data() {
    return {
      mixinDialogWalletVisible: false,
      mixinDialogWalletVisibleReal: false,
      mixinDialogWalletSendVisible: false,
      mixinDialogWalletSendVisibleReal: false
    }
  },
  methods: {
    mixinSend() {
      this.mixinDialogWalletVisible = false
      this.mixinDialogWalletSendVisible = true
      this.mixinDialogWalletSendVisibleReal = true
    },
    mixinDialogWalletClosed() {
      this.mixinDialogWalletVisibleReal = false
    },
    mixinDialogWalletSendClosed() {
      this.mixinDialogWalletSendVisibleReal = false
    },
    mixinOpenWallet() {
      this.mixinDialogWalletVisible = true
      this.mixinDialogWalletVisibleReal = true
    }
  },
  components: {
    WalletInfo,
    WalletSend
  }
}

export default mixin
